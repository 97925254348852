import {WSOption} from '@component/select/WizSelectBox'
import {useTranslation} from 'next-i18next'
import {DayDuration} from '@type/time'
import React, {useMemo, useState} from 'react'
import {getDateFromYYYYMMDD} from '@util/dates'
import useQueryGetCopierChart from '@hook/query/portfolio/copyfolio/useQueryCopierGraph'
import useWindowSize from '@hook/useWindowSize'
import WizPortfolioAreaChart from '@component/charts/WizPortfolioAreaChart/WizPortfolioAreaChart'
import WizSegmentedControl from '@component/segmentedControl/WizSegmentedControl'
import {createLog} from '@util/logs'

interface IProps {
    type: 'user' | 'key'
    id: string
}

type ChartType = 'earning' | 'balance'
const CopyfolioChartContainer: React.FC<IProps> = ({type, id}) => {
    const {t} = useTranslation()
    const [chartType, setChartType] = useState<ChartType>('earning')

    const {isSm} = useWindowSize()
    const graphTypeOptions: WSOption[] = useMemo(
        () =>
            [
                {
                    id: 'earning',
                    text: t('portfolio.dashboard.profitGraph.selector.type.earning'),
                    value: 'earning',
                },
                {
                    id: 'balance',
                    text: t('portfolio.dashboard.profitGraph.selector.type.balance'),
                    value: 'balance',
                },
            ] as {id: ChartType; text: string; value: ChartType}[],
        [t],
    )

    const {data: chart} = useQueryGetCopierChart(type, id, DayDuration.Lifetime)
    const chartData = useMemo(() => {
        if (!chart || chart.length === 0) return []
        return chart.map(item => {
            const date = getDateFromYYYYMMDD(item.day)
            if (chartType === 'earning') {
                return {x: date.getTime(), y: item.earning}
            }
            if (chartType === 'balance') {
                return {x: date.getTime(), y: item.balance}
            }
        })
    }, [chart, chartType])

    const onChangeChartType = (type: ChartType) => {
        createLog('event', 'copyfolio_chart_change_type', {type})
        setChartType(type)
    }

    return (
        <>
            <WizSegmentedControl
                className={'mt-6'}
                selectorKind={'clear'}
                buttonSize={'medium'}
                selectedOption={chartType}
                options={graphTypeOptions}
                onChange={onChangeChartType}
            />
            <WizPortfolioAreaChart chartData={chartData} chartType={chartType} chartPeriod={'1000'} height={245} />
        </>
    )
}

export default CopyfolioChartContainer
