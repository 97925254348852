import React, {useMemo, useRef} from 'react'
import WizTable, {IWizTableColumn} from '@component/tables/WizTable/WizTable'
import TableHeader from '@component/tables/TableHeader'
import {useTranslation} from 'next-i18next'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import useFormatDate from '@hook/useFormatDate'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import {ICopier} from '@api/copy/leader/getLeaderKeysForDashboard'
import EmptyComponent from '@feature/common/components/EmptyComponent/EmptyComponent'
import useQueryGetLeaderCopierAll from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderCopierAll'
import {getDayDifferenceFromDate, getHourDifferenceFromDate} from '@util/dates'
import IconInfoDark from '@svg/common/ic_info_dark.svg'
import Info from '@svg/common/ic_info.svg'
import useDark from '@hook/useDark'
import LeaderCopierRecoveryModal from '@feature/copy/components/LeaderCopierRecoveryModal/LeaderCopierRecoveryModal'
import Spinner from '@component/Spinner/Spinner'

interface IProps {
    className?: string
    leaderId?: string
    copiers?: ICopier[]
    renderHeader?: () => React.ReactNode
    enableOnClick?: boolean
    hoverBorderClassName?: string
    onClick?: () => void
    copiersCount?: number
    isViewAll?: boolean
    onClickViewAll?: () => void
    keyId?: string
    isMe?: boolean
    isFetching?: boolean
}

const LeaderKeyTable: React.FC<IProps> = ({
    className,
    copiers = [],
    renderHeader,
    hoverBorderClassName,
    enableOnClick,
    onClick,
    leaderId,
    copiersCount,
    isViewAll,
    onClickViewAll,
    keyId,
    isMe,
    isFetching,
}) => {
    const {t} = useTranslation()
    const {displayDateFromText} = useFormatDate()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {isRenderDark} = useDark()
    const leaderCopierRecoveryModalRed = useRef<any>(null)
    const {data: data} = useQueryGetLeaderCopierAll(keyId)

    const copierAll = useMemo(() => {
        const list: ICopier[] = []
        data?.pages?.map(page => page?.map(item => list?.push(item)))
        return list
    }, [data])

    const columns: IWizTableColumn<ICopier>[] = [
        {
            key: 'copier',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.copier')} />
            },
            cell: item => {
                return (
                    <>
                        <div className={'flex items-center py-[10px] space-x-[5px]'}>
                            <UserThumbnail src={item?.thumbnail} size={24} containerClassName={'sm:hidden flex-none'} />

                            <div>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 grow break-all'}>
                                    {item?.username}
                                </Text>
                                {item?.owed_earning ? (
                                    <div className={'flex items-center gap-x-[3px]'}>
                                        <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                                            {t('copyfolio.copyTrading.recovery.title')}
                                        </Text>
                                        {isRenderDark ? (
                                            <IconInfoDark
                                                onClick={e => {
                                                    leaderCopierRecoveryModalRed?.current?.show()
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                                className={
                                                    'w-[12px] fill-bg_dark_white03 hover:fill-bg_dark_white01 cursor-pointer'
                                                }
                                            />
                                        ) : (
                                            <Info
                                                onClick={e => {
                                                    leaderCopierRecoveryModalRed?.current?.show()
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                                className={'w-[12px] fill-white hover:fill-gray08 cursor-pointer'}
                                            />
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </>
                )
            },
            thClassName: 'w-[230px]',
        },
        {
            key: 'start_date',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.startDate')} align={'left'} />
            },
            cell: item => (
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-left'}>
                    {displayDateFromText(item?.start_at, t('dateFormat.date'))}
                </Text>
            ),
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'current_balance',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.currentBalance')} align={'right'} />
            },
            cell: item => (
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-right'}>
                    {displayBalance(item?.balance, {showPreSign: true})}
                </Text>
            ),
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'profit',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.profit')} align={'right'} />
            },
            cell: item => (
                <Text className={`text-body2 ${getNumberColorClass(item?.pnl)} text-right`}>
                    {displayBalance(item?.pnl, {showPreSign: true})} ({displayPercent(item?.profit_rate)})
                </Text>
            ),
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'mobile_profit',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.profit')} align={'right'} />
            },
            cell: item => (
                <div className={'py-[7px]'}>
                    <Text className={`text-body2 text-right ${getNumberColorClass(item?.pnl)}`}>
                        {`${displayBalance(item?.pnl, {showPreSign: true})}`}
                    </Text>
                    <Text className={`text-body2 text-right ${getNumberColorClass(item?.pnl)}`}>
                        {`(${displayPercent(item?.profit_rate)})`}
                    </Text>
                </div>
            ),
            visibilityClassName: 'hidden md:table-cell sm:hidden',
        },

        {
            key: 'total',
            header: () => {
                return <TableHeader title={t('userLeaderfolio.copierTable.column.total')} align={'right'} />
            },
            cell: item => (
                <div>
                    {item?.paid_leader > 0 ? (
                        <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-right font-bold'}>
                            {displayBalance(item?.paid_leader, {showPreSign: true})}
                        </Text>
                    ) : (
                        <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-right font-bold'}>-</Text>
                    )}
                </div>
            ),
            visibilityClassName: `${isMe === true ? 'table-cell' : 'hidden'}`,
        },
        {
            key: 'income',
            header: () => {
                return (
                    <TableHeader
                        title={t('userLeaderfolio.copierTable.column.income')}
                        align={'right'}
                        className={'md:hidden'}
                    />
                )
            },
            cell: item => (
                <div>
                    {item?.expected_pay >= 0 ? (
                        <Text className={'text-body2 text-gray03 dark:text-dark_gray03 text-right'}>
                            {displayBalance(item?.expected_pay, {showPreSign: true})}{' '}
                            {item?.pending_pay
                                ? t('userLeaderfolio.copierTable.column.income.value.panding')
                                : getDayDifferenceFromDate(item?.next_payment) > 0
                                ? t('userLeaderfolio.copierTable.column.income.value.days', {
                                      days: displayNumber(getDayDifferenceFromDate(item?.next_payment)),
                                  })
                                : t('userLeaderfolio.copierTable.column.income.value.hours', {
                                      hours: displayNumber(
                                          getHourDifferenceFromDate(item?.next_payment) > 0
                                              ? getHourDifferenceFromDate(item?.next_payment)
                                              : 1,
                                      ),
                                  })}
                        </Text>
                    ) : (
                        <Text className={'text-body2 text-gray03 dark:text-dark_gray03 text-right'}>-</Text>
                    )}
                </div>
            ),
            visibilityClassName: `${isMe === true ? 'table-cell md:hidden' : 'hidden'}`,
        },
    ]

    return (
        <>
            <WizTable
                className={`${className} ${
                    renderHeader
                        ? 'border border-transparent02 dark:border-bg_dark_white01 shadow-normal rounded-[5px]'
                        : ''
                }`}
                tableClassName={'table-fixed'}
                data={isViewAll ? copierAll : copiers}
                count={copiersCount}
                onClickViewAll={onClickViewAll}
                columns={columns}
                extractKey={item => item?.key_id}
                renderHeader={renderHeader}
                enableOnClick={enableOnClick}
                hoverBorderClassName={hoverBorderClassName}
                onClick={onClick}
                renderEmpty={() =>
                    isFetching ? (
                        <div className={'flex min-h-[84px] items-center justify-center'}>
                            <Spinner size={'smallest'} visible={true} />
                        </div>
                    ) : (
                        <EmptyComponent text={t('userLeaderfolio.copierTable.empty')} className={'min-h-[84px]'} />
                    )
                }
                header={{
                    borderClassName: `${
                        renderHeader ? 'border-y' : 'border'
                    } border-transparent01 dark:border-bg_dark_white01 `,
                    bgClassName: 'bg-gray09 dark:bg-bg_dark_white02',
                    paddingHorizontal: 20,
                }}
            />
            <LeaderCopierRecoveryModal ref={leaderCopierRecoveryModalRed} />
        </>
    )
}

export default LeaderKeyTable
