import React, {useRef} from 'react'
import {IKeyPortfolio} from '@api/key/portfolio/getKeyPortfolio'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'
import useFormatDate from '@hook/useFormatDate'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import {ILeaderInfo} from '@api/key/leaderfolio/getLeaderInfo'
import UserThumbnail from '@component/images/UserThumbnail'
import CopierCount from '@feature/common/components/CopierCount/CopierCount'
import DeleteLeaderModal from '@feature/copy/components/DeleteLeaderModal/DeleteLeaderModal'
import useNavigation from '@hook/useNavigation'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import {useRouter} from 'next/router'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import {cutEndString} from '@util/strings'
import IconLink from '@svg/common/ic_copy_link.svg'
import WizIcon from '@component/images/WizIcon'
import IconBadgeLeader from '@svg/copytrading/ic_badge_leader.svg'
import {createLog} from '@util/logs'
import FavoriteFilterButton from '@feature/leaderboard/components/FilterButton/FavoriteFilterButton'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import CreateCopyModal from '@feature/copy/components/CreateCopyModal/CreateCopyModal'
import useQueryGetKeySidebar from '@hook/query/portfolio/useQueryGetKeySidebar'
import useLogin from '@hook/useLogin'
import {useLoginModalStore} from '@store/LoginModalStore'

interface IProps extends IKeyPortfolio {
    leaderInfo?: ILeaderInfo
    className?: string
    userId?: string
    onClickFavorite?: () => void
    isFavorite?: boolean
    isFetching?: boolean
    isMe?: boolean
}

const KeyLeaderfolioHeader: React.FC<IProps> = ({
    className,
    exchange,
    key_name,
    created_at,
    id,
    leaderInfo,
    userId,
    onClickFavorite,
    isFavorite,
    isFetching,
    isMe,
}) => {
    const {t} = useTranslation()

    const {data: sidebar} = useQueryGetKeySidebar(id, false)
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {isLogin} = useLogin(sidebar?.user_id)

    const {getExchange} = useQueryGetExchanges()
    const {displayDateFromText, displayDate} = useFormatDate()
    const deleteModalRef = useRef<any>()
    const createCopyModalRef = useRef<any>()
    const {goUserLeaderfolio} = useNavigation()

    const onClickStopLeader = () => {
        createLog('event', 'leader_key_portfolio_header_click_stop_leader_button')
        deleteModalRef?.current?.show()
    }
    const router = useRouter()

    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()

    return (
        <div className={'w-full'}>
            <div className={`w-full flex gap-y-[10px] items-center sm:flex-col sm:items-start ${className}`}>
                <div className={'flex items-center flex-1 grow'}>
                    {isFetching ? (
                        <SkeletonBlock className={'w-[50px] h-[50px]'} sizeClassName={'h-full'} />
                    ) : (
                        <UserThumbnail src={leaderInfo?.thumbnail} size={50} containerClassName={'flex-none'} />
                    )}
                    <div className={'flex-none ml-[10px] space-y-[3px] sm:space-y-0'}>
                        <div className={'flex items-center'}>
                            {isFetching ? (
                                <SkeletonBlock className={'w-[150px] h-[30px]'} sizeClassName={'h-[20px]'} />
                            ) : (
                                <Text className="text-gray01 dark:text-dark_gray01">
                                    <Span className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                                        {getExchange(exchange)?.display_name}
                                    </Span>
                                    <Span
                                        className={`text-h3 text-gray02 dark:text-dark_gray02 ml-[5px] hidden md:inline-block`}>
                                        {leaderInfo?.name?.length >= 9
                                            ? cutEndString(leaderInfo?.name, 6)
                                            : leaderInfo?.name}
                                    </Span>
                                    <Span className={`text-h3 text-gray02 dark:text-dark_gray02 ml-[5px] md:hidden`}>
                                        {leaderInfo?.name}
                                    </Span>
                                </Text>
                            )}
                            <WizIcon
                                className={`${router?.asPath?.includes('portfolio') ? 'block' : 'hidden'} ml-[5px]`}
                                onClick={() => {
                                    createLog('event', 'leader_key_portfolio_header_copy_key_portfolio_link')
                                    copyToClipboard(`${window.location.origin}/${router.locale}/key/${id}/portfolio`)
                                    showSnackbar(t('common.copied'))
                                }}
                                backgroundColorClassName={
                                    'hover:bg-transparent01 dark:bg-dark_gray07 dark:hover:bg-dark_gray06 p-[5px] cursor-pointer fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 '
                                }>
                                <IconLink className={'w-[14px]'} />
                            </WizIcon>
                            <IconBadgeLeader className={'ml-[5px] mr-[10px]'} />
                            {isMe ? (
                                isFetching ? (
                                    <SkeletonBlock className={'w-[48px] h-[21px]'} sizeClassName={'h-[14px]'} />
                                ) : (
                                    <CopierCount
                                        copier={leaderInfo?.copiers}
                                        maxCopier={leaderInfo?.max_copiers}
                                        className={'sm:hidden'}
                                    />
                                )
                            ) : null}
                        </div>

                        {isFetching ? (
                            <SkeletonBlock className={'w-[120px] h-[18px]'} sizeClassName={'h-[12px]'} />
                        ) : (
                            <div className={'flex gap-x-[5px]'}>
                                {key_name ? (
                                    <Text className={'text-ti3 text-gray03 dark:text-dark_gray03'}>
                                        {key_name?.length >= 15 ? cutEndString(key_name) : key_name}
                                    </Text>
                                ) : (
                                    <></>
                                )}
                                <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                    {t('keyLeaderfolio.header.start', {
                                        date: displayDateFromText(leaderInfo?.start_at, t('dateFormat.date')),
                                    })}
                                </Text>
                            </div>
                        )}
                        {isMe ? (
                            <div className={'items-center gap-[3px] hidden sm:flex sm:mt-[5px]'}>
                                <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                    {t('keyLeaderfolio.copiers.title')}:
                                </Text>
                                {isFetching ? (
                                    <SkeletonBlock className={'w-[48px] h-[21px]'} sizeClassName={'h-[14px]'} />
                                ) : (
                                    <Text className={'text-ti3'}>
                                        <Span className={'text-gray02 dark:text-dark_gray02'}>
                                            {leaderInfo?.copiers}
                                        </Span>
                                        <Span className={'text-gray03 dark:text-dark_gray03'}>
                                            /{leaderInfo?.max_copiers}
                                        </Span>
                                    </Text>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                {isMe && (
                    <div className={'grow flex justify-end space-x-[5px] items-center sm:w-full'}>
                        <WizButton
                            className={'sm:grow max-h-[35px]'}
                            text={t('keyLeaderfolio.header.stopLeading')}
                            buttonType={'border_red'}
                            size={'small'}
                            onClick={onClickStopLeader}
                        />
                    </div>
                )}
                {!isMe && (
                    <FavoriteFilterButton
                        className={'sm:hidden'}
                        title={t('leader.favorite.button.title')}
                        isSelected={isFavorite}
                        onClick={() => {
                            onClickFavorite()
                        }}
                    />
                )}

                {!isFetching && isMe === false && (
                    <div
                        className={'hidden sm:block mt-[10px] w-full'}
                        onClick={e => {
                            e.stopPropagation()
                            if (sidebar?.can_copy === false) {
                                showSnackbar(t('portfolio.sidebar.copy.unavailable'))
                                return
                            }

                            if (!isLogin()) {
                                showLoginModal()
                                return
                            }
                            createLog('event', 'key_portfolio_sidebar_open_copy_modal')
                            createCopyModalRef?.current?.show()
                        }}>
                        <WizButton
                            textClassName={''}
                            text={t('portfolio.sidebar.mobile.copy.title')}
                            className={'w-full'}
                            size={'small'}
                        />
                    </div>
                )}
            </div>
            <CreateCopyModal ref={createCopyModalRef} leaderUserId={userId} />
            <DeleteLeaderModal ref={deleteModalRef} id={id} refreshCallback={() => goUserLeaderfolio(userId)} />
        </div>
    )
}

export default KeyLeaderfolioHeader
