import React, {useMemo, useRef} from 'react'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'
import useFormatDate from '@hook/useFormatDate'
import {useTranslation} from 'next-i18next'
import {IKeyCopyfolio} from '@api/key/copyfolio/getKeyCopyfolio'
import BotStatusTag from '@component/tags/BotStatusTag'
import WizButton from '@component/buttons/WizButton'
import DeleteCopierModal from '@feature/copy/components/DeleteCopierModal/DeleteCopierModal'
import WizIconButton from '@component/buttons/WizIconButton'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import {useRouter} from 'next/router'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import {cutEndString} from '@util/strings'
import WizTooltip from '@component/tooltip/WizTooltip'
import Info from '@svg/common/ic_info.svg'
import TagText from '@component/Text/TagText'
import {getDayDifference} from '@util/dates'
import useWindowSize from '@hook/useWindowSize'
import IconLink from '@svg/common/ic_copy_link.svg'
import WizIcon from '@component/images/WizIcon'
import IconBadgeCopier from '@svg/copytrading/ic_badge_copy.svg'
import IconInfoDark from '@svg/common/ic_info_dark.svg'
import useDark from '@hook/useDark'
import {createLog} from '@util/logs'
import ResumeCopyTradingModal from '@feature/copy/components/ResumeCopyTradingModal/ResumeCopyTradingModal'
import useQueryGetUserCopyDashboard from '@hook/query/portfolio/copyfolio/useQueryGetCopyDashboard'
import DeleteStoppedCopierModal from '@feature/copy/components/DeleteStoppedCopierModal/DeleteStoppedCopierModal'
import IconInfoRound from '@svg/common/ic_info_round.svg'
import LossRecoveryModal from '@feature/copy/components/LossRecoveryModal/LossRecoveryModal'
import useQueryGetKeyProfile from '@hook/query/portfolio/useQueryGetKeyProfile'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    deleteCopierCallback?: () => void
    data?: IKeyCopyfolio
    isFetching?: boolean
}

const KeyCopyfolioHeader: React.FC<IProps> = ({className, deleteCopierCallback, data, isFetching}) => {
    const {
        next_payment_date,
        pending_invoice,
        profit,
        user_id,
        exchange,
        name,
        start_at,
        id,
        status,
        owed_earning,
        next_owed_earning,
        cant_resume_reason,
    } = {...data}
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {displayDateFromText} = useFormatDate()
    const deleteCopierModalRef = useRef<any>()
    const resumeCopyTradingModalRef = useRef<any>()
    const deleteModal = useRef<any>()
    const lossRecoveryModalRef = useRef<any>()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const router = useRouter()
    const {isSm} = useWindowSize()
    const {isRenderDark} = useDark()
    const {refresh} = useQueryGetUserCopyDashboard(user_id)
    const {refresh: refreshKeyDetail} = useQueryGetKeyProfile(id, false)

    const getInvoiceDate = useMemo(() => {
        return getDayDifference(new Date(), new Date(next_payment_date))
    }, [next_payment_date])

    return (
        <>
            <div className={`grid grid-cols-2 sm:grid-cols-1 gap-y-[10px] items-center w-full ${className}`}>
                <div className={'flex'}>
                    {isFetching ? (
                        <SkeletonBlock className={'w-[50px] h-[50px]'} sizeClassName={'h-full'} />
                    ) : (
                        <div
                            className={
                                'w-[50px] h-[50px] bg-white dark:bg-dark_gray09 rounded-full flex items-center justify-center border-[2px] border-gray08 dark:border-dark_gray08 flex-none'
                            }>
                            <WizImage
                                src={getExchange(exchange)?.icon}
                                width={30}
                                height={30}
                                containerClassName={'flex-none'}
                            />
                        </div>
                    )}
                    <div className={'flex-none ml-[10px] space-y-[3px]'}>
                        <div className={'flex items-center'}>
                            {isFetching ? (
                                <SkeletonBlock className={'w-[150px] h-[30px]'} sizeClassName={'h-[20px]'} />
                            ) : (
                                <Text className={'word-break'}>
                                    <Span className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                                        {cutEndString(getExchange(exchange)?.display_name)}
                                    </Span>
                                    {name && (
                                        <Span className={'text-h3 text-gray02 dark:text-dark_gray02 ml-[5px]'}>
                                            {cutEndString(name, isSm ? 10 : 25)}
                                        </Span>
                                    )}
                                </Text>
                            )}
                            <IconBadgeCopier className={'mx-[5px]'} />
                            <WizIconButton
                                className={`${router?.asPath?.includes('portfolio') ? 'block' : 'hidden'}`}
                                onClick={() => {
                                    createLog('event', 'copier_key_portfolio_header_copy_user_link')
                                    copyToClipboard(`${window.location.origin}/${router.locale}/key/${id}/portfolio`)
                                    showSnackbar(t('common.copied'))
                                }}>
                                <WizIcon
                                    backgroundColorType={'default'}
                                    backgroundColorClassName={
                                        ' hover:bg-transparent01 dark:hover:bg-dark_transparent01 p-[5px]'
                                    }>
                                    <IconLink
                                        className={
                                            'fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 w-[14px]'
                                        }
                                    />
                                </WizIcon>
                            </WizIconButton>
                        </div>

                        {isFetching ? (
                            <SkeletonBlock className={'w-[300px] h-[22px]'} sizeClassName={'h-[15px]'} />
                        ) : (
                            <div className={'flex items-center gap-x-[5px]'}>
                                <BotStatusTag status={status} />
                                <div
                                    className={
                                        'flex items-center gap-x-[5px] sm:flex-col sm:items-start sm:gap-x-[0px]'
                                    }>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                        {t('keyCopyfolio.header.start', {
                                            date: displayDateFromText(start_at, t('dateFormat.date')),
                                        })}
                                    </Text>
                                    {profit > 0 && (
                                        <>
                                            {pending_invoice ? (
                                                <div className={'flex items-center'}>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 '}>
                                                        {t('keyCopyfolio.header.invoiceDate.text')}
                                                    </Text>
                                                    <Text
                                                        className={
                                                            'text-ti3 text-gray03 dark:text-dark_gray03 pr-[5px]'
                                                        }>
                                                        {displayDateFromText(
                                                            pending_invoice?.due_at,
                                                            t('dateFormat.dateAndTime'),
                                                        )}
                                                    </Text>
                                                    <WizTooltip
                                                        type={'key_copyfolio_invoice_date'}
                                                        title={t('keyCopyfolio.header.invoiceDate.tooltip.title')}
                                                        render={
                                                            isRenderDark ? (
                                                                <IconInfoDark
                                                                    className={
                                                                        'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01'
                                                                    }
                                                                />
                                                            ) : (
                                                                <Info
                                                                    className={'w-[20px] fill-white hover:fill-gray08'}
                                                                />
                                                            )
                                                        }>
                                                        <TagText
                                                            className={
                                                                'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'
                                                            }>
                                                            {t('keyCopyfolio.header.invoiceDate.tooltip.content')}
                                                        </TagText>
                                                    </WizTooltip>
                                                </div>
                                            ) : (
                                                <div className={'flex items-center'}>
                                                    {next_payment_date && status === 'running' && (
                                                        <>
                                                            <Text
                                                                className={
                                                                    'text-body3 text-gray03 dark:text-dark_gray03'
                                                                }>
                                                                {t('keyCopyfolio.header.invoiceDate.text')}
                                                            </Text>
                                                            <TagText
                                                                className={
                                                                    'text-ti3 text-gray03 dark:text-dark_gray03 pr-[5px]'
                                                                }>
                                                                {getInvoiceDate <= 0
                                                                    ? displayDateFromText(
                                                                          next_payment_date,
                                                                          t('dateFormat.dateAndTime'),
                                                                      )
                                                                    : t('keyCopyfolio.header.invoiceDate.day', {
                                                                          count: getInvoiceDate,
                                                                      })}
                                                            </TagText>
                                                            <WizTooltip
                                                                type={'key_copyfolio_invoice_date'}
                                                                title={t(
                                                                    'keyCopyfolio.header.invoiceDate.tooltip.title',
                                                                )}
                                                                render={
                                                                    isRenderDark ? (
                                                                        <IconInfoDark
                                                                            className={
                                                                                'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01'
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Info
                                                                            className={
                                                                                'w-[20px] fill-white hover:fill-gray08'
                                                                            }
                                                                        />
                                                                    )
                                                                }>
                                                                <TagText
                                                                    className={
                                                                        'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'
                                                                    }>
                                                                    {t(
                                                                        'keyCopyfolio.header.invoiceDate.tooltip.content',
                                                                    )}
                                                                </TagText>
                                                            </WizTooltip>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {status === 'running' && (
                    <div className={'grow flex justify-end space-x-[5px] items-center sm:w-full'}>
                        <WizButton
                            className={'sm:grow max-h-[35px]'}
                            size={'small'}
                            text={t('keyCopyfolio.header.stopCopy')}
                            buttonType={'border_red'}
                            onClick={() => {
                                createLog('event', 'copier_key_portfolio_header_click_stop_leader_button')
                                deleteCopierModalRef?.current?.show()
                            }}
                        />
                    </div>
                )}
                {status !== 'running' && !pending_invoice && (
                    <div>
                        <div className={'grow flex justify-end gap-x-[5px] sm:w-full'}>
                            <WizButton
                                text={t('keyCopyfolio.header.unlink')}
                                buttonType={'border'}
                                size={'small'}
                                onClick={() => deleteModal?.current?.show()}
                                className={'sm:w-full'}
                            />
                            <WizButton
                                text={t('keyCopyfolio.header.resume')}
                                size={'small'}
                                onClick={() => resumeCopyTradingModalRef?.current?.show(data)}
                                className={'sm:w-full'}
                            />
                        </div>
                        {next_owed_earning && !cant_resume_reason?.includes('leader_key_not_found') && (
                            <div
                                onClick={e => {
                                    lossRecoveryModalRef?.current?.show()
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                                className={
                                    'flex items-center justify-end sm:inline sm:text-center mt-[5px] cursor-pointer'
                                }>
                                {router?.locale === 'ko' ? (
                                    <>
                                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                            {t('copyfolio.copyTrading.recovery.table.text')}
                                        </Text>
                                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                            {t('copyfolio.copyTrading.recovery')}
                                        </Text>
                                        <IconInfoRound
                                            className={'w-[12px] fill-gray02 dark:fill-dark_gray02 ml-[2px] sm:inline'}
                                        />
                                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                            {t('copyfolio.copyTrading.recovery.table.text.ko')}
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                            {t('copyfolio.copyTrading.recovery')}
                                        </Text>
                                        <IconInfoRound
                                            className={'w-[12px] fill-gray02 dark:fill-dark_gray02 ml-[2px] sm:inline'}
                                        />
                                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                            {t('copyfolio.copyTrading.recovery.table.text')}
                                        </Text>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <DeleteStoppedCopierModal
                ref={deleteModal}
                id={id}
                onDeleteComplete={() => {
                    refresh()
                    refreshKeyDetail()
                }}
            />

            <ResumeCopyTradingModal
                resumeKeyId={id}
                onDeleteComplete={() => {
                    refresh()
                    refreshKeyDetail()
                }}
                ref={resumeCopyTradingModalRef}
            />
            <DeleteCopierModal
                ref={deleteCopierModalRef}
                id={id}
                refreshCallback={() => deleteCopierCallback && deleteCopierCallback()}
            />
            <LossRecoveryModal ref={lossRecoveryModalRef} loss={next_owed_earning} />
        </>
    )
}

export default KeyCopyfolioHeader
