import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import TextHighlight from '@feature/portfolio/components/TextHighlight/TextHighlight'
import useQueryGetCopyDashboard from '@hook/query/portfolio/copyfolio/useQueryGetCopyDashboard'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    userID?: string
}

const CopyBalanceHeader: React.FC<IProps> = ({userID}) => {
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()
    const {data, isLoading, isFetching, isSuccess} = useQueryGetCopyDashboard(userID)

    return (
        <div className={'flex justify-between'}>
            <div className={''}>
                <Text className={'text-ti3 text-gray02 dark:text-dark_gray02 font-bold'}>
                    {t('copyBalanceHeader.currentCopyEarnings')}
                </Text>
                {isLoading && isFetching && !isSuccess ? (
                    <SkeletonBlock className={'w-[150px] h-[54px]'} sizeClassName={'h-[36px]'} />
                ) : (
                    <TextHighlight
                        textColorClassName={
                            data?.total_copy_earning > 0
                                ? 'text-primary_shade dark:text-dark_primary_shade'
                                : data?.total_copy_earning === 0
                                ? 'text-gray01 dark:text-dark_gray01'
                                : 'text-red_shade dark:text-dark_red_shade'
                        }
                        bgColorClassName={
                            data?.total_copy_earning >= 0
                                ? 'bg-primary dark:bg-dark_primary'
                                : 'bg-red dark:bg-dark_red'
                        }
                        text={displayBalance(data?.total_copy_earning, {showPreSign: true})}
                    />
                )}
                <div className={'flex items-center gap-x-[5px]'}>
                    {isLoading && isFetching && !isSuccess ? (
                        <SkeletonBlock className={'w-[70px] h-[21px]'} sizeClassName={'h-[14px]'} />
                    ) : (
                        <Text className={`text-ti2 ${getNumberColorClass(data?.last_day_earning)}`}>
                            {displayBalance(data?.last_day_earning, {showPostSymbol: true})}
                        </Text>
                    )}
                    <Text className={'text-body2 text-gray03 dark:text-dark_gray03 ml-[5px]'}>
                        {t('copyBalanceHeader.last24hours')}
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default CopyBalanceHeader
