import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import img_start_copytrading_en_pc from '@image/portfolio/img_start_paid_copytrading_en_pc.webp'
import img_start_copytrading_en_pc_dark from '@image/portfolio/img_start_paid_copytrading_en_pc_dark.webp'
import img_start_copytrading_ko_pc from '@image/portfolio/img_start_paid_copytrading_ko_pc.webp'
import img_start_copytrading_ko_pc_dark from '@image/portfolio/img_start_paid_copytrading_ko_pc_dark.webp'
import img_start_copytrading_en_mo from '@image/portfolio/img_start_paid_copytrading_en_mo.webp'
import img_start_copytrading_en_mo_dark from '@image/portfolio/img_start_paid_copytrading_en_mo_dark.webp'
import img_start_copytrading_ko_mo from '@image/portfolio/img_start_paid_copytrading_ko_mo.webp'
import img_start_copytrading_ko_mo_dark from '@image/portfolio/img_start_paid_copytrading_ko_mo_dark.webp'
import WizButton from '@component/buttons/WizButton'
import TagSpan from '@component/Text/TagSpan'
import useNavigation from '@hook/useNavigation'
import {useRouter} from 'next/router'
import useDark from '@hook/useDark'
import {createLog} from '@util/logs'

interface IProps {}

const EmptyCopyfolioMineContainer: React.FC<IProps> = ({}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {isRenderDark} = useDark()
    const {goLeaderboard} = useNavigation()

    return (
        <div className={'flex flex-col items-center'} data-cy={'copyfolio_empty'}>
            <Text className={'text-h2 text-gray01 dark:text-dark_gray01 mt-[60px] text-center'}>
                {t('copyfolio.empty.mine.title')}
            </Text>
            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 text-center '}>
                {t('copyfolio.empty.mine.subtitle')}
            </Text>
            {router?.locale === 'ko' ? (
                <>
                    <WizImage
                        src={isRenderDark ? img_start_copytrading_ko_pc_dark : img_start_copytrading_ko_pc}
                        height={300}
                        containerClassName={'sm:hidden mt-[20px]'}
                    />
                    <WizImage
                        src={isRenderDark ? img_start_copytrading_ko_mo_dark : img_start_copytrading_ko_mo}
                        width={320}
                        containerClassName={'hidden sm:block mt-[10px] mx-auto'}
                    />
                </>
            ) : (
                <>
                    <WizImage
                        src={isRenderDark ? img_start_copytrading_en_pc_dark : img_start_copytrading_en_pc}
                        height={300}
                        containerClassName={'sm:hidden mt-[20px]'}
                    />
                    <WizImage
                        src={isRenderDark ? img_start_copytrading_en_mo_dark : img_start_copytrading_en_mo}
                        width={320}
                        containerClassName={'hidden sm:block  mt-[10px] mx-auto'}
                    />
                </>
            )}
            <div className={'max-w-[900px] mt-[50px]'}>
                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>{t('copyfolio.notice.title')}</Text>
                <ul className={'list-disc list-outside mt-[10px] ml-[30px] text-gray02 dark:text-dark_gray02 '}>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.line01')}
                        </TagSpan>
                    </li>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.line02')}
                        </TagSpan>
                    </li>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.line03')}
                        </TagSpan>
                    </li>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.line04')}
                        </TagSpan>
                    </li>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.line05')}
                        </TagSpan>
                    </li>
                </ul>

                <Text className={'mt-[20px] text-body1 text-gray02 dark:text-dark_gray02'}>
                    {t('copyfolio.notice.tobePaid.content.service')}
                </Text>
                <ul className={'list-disc list-outside ml-[30px] mt-[5px] text-gray02 dark:text-dark_gray02'}>
                    <li>
                        <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.notice.tobePaid.content.service.line01')}
                        </TagSpan>
                    </li>
                </ul>
            </div>

            <WizButton
                text={t('copyfolio.empty.mine.redirect')}
                className={'mt-[30px]'}
                buttonType={'primary'}
                size={'large'}
                onClick={() => {
                    createLog('event', 'copyfolio_empty_connect_exchange')
                    goLeaderboard('copyleader')
                }}
            />
        </div>
    )
}

export default EmptyCopyfolioMineContainer
