import React from 'react'
import EmptyCopyfolioMineContainer from '@feature/portfolio/pages/userCopyfolio/EmptyCopyfolioContainer/EmptyCopyfolioMineContainer'
import EmptyCopyfolioOtherContainer from '@feature/portfolio/pages/userCopyfolio/EmptyCopyfolioContainer/EmptyCopyfolioOtherContainer'

interface IProps {
    id: string
    login_id: string
}

const EmptyCopyfolioContainer: React.FC<IProps> = ({id, login_id}) => {
    const isMine = id === login_id

    return (
        <>
            {isMine && <EmptyCopyfolioMineContainer />}
            {!isMine && <EmptyCopyfolioOtherContainer />}
        </>
    )
}

export default EmptyCopyfolioContainer
