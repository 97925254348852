import React from 'react'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import img_start_copytrading from '@image/img_start_copytrading.png'
import WizButton from '@component/buttons/WizButton'
import {useTranslation} from 'next-i18next'
import useNavigation from '@hook/useNavigation'
import {createLog} from '@util/logs'

interface IProps {}

const EmptyCopyfolioOtherContainer: React.FC<IProps> = ({}) => {
    const {t} = useTranslation()
    const {goLeaderboard} = useNavigation()

    return (
        <div className={'flex flex-col items-center'}>
            <Text className={'text-h2 text-gray01 dark:text-dark_gray01 text-center mt-[60px]'}>
                {t('portfolio.empty.other.title')}
            </Text>
            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 text-center'}>
                {t('portfolio.empty.other.subtitle')}
            </Text>
            <WizImage src={img_start_copytrading} height={200} containerClassName={'mt-[20px]'} />
            <WizButton
                text={t('portfolio.empty.other.leaderboard')}
                className={'mt-[20px]'}
                buttonType={'primary'}
                size={'large'}
                onClick={() => {
                    createLog('event', 'copyfolio_empty_click_leaderboard_button')
                    goLeaderboard()
                }}
            />
        </div>
    )
}

export default EmptyCopyfolioOtherContainer
