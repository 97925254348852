import React from 'react'
import Text from '@component/Text/Text'
import Title from '@feature/common/components/Title/Title'
import useNavigation from '@hook/useNavigation'
import {useTranslation} from 'next-i18next'
import UserCopyHistoryTable from '@feature/portfolio/components/tables/CopyHistoryTable/UserCopyHistoryTable'
import {ICopyHistory} from '@api/copy/copier/history/getCopyHistory'
import {createLog} from '@util/logs'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import IconPlus from '@svg/common/ic_btn_add.svg'

interface IProps {
    className?: string
    isMe: boolean
    id: string
    copyHistory?: ICopyHistory[]
    isFetching?: boolean
}

const RecommendCopyTradingContainer: React.FC<IProps> = ({className, isMe, id, copyHistory, isFetching}) => {
    const {goUserCopyfolioHistory, goLeaderboard} = useNavigation()

    const {t} = useTranslation()

    const onClickCopyHistory = () => {
        createLog('event', 'copyfolio_click_copy_trading')
        goUserCopyfolioHistory(id)
    }

    const onClickSendToLeaderboard = () => {
        createLog('event', 'copyfolio_click_copy_leaderboard')
        goLeaderboard('copyleader')
    }

    return (
        <div className={`${className} p-[10px]`} data-cy={'copyfolio_noneCopyTrading_hasHistory'}>
            <div className={'py-[30px] text-center'}>
                <Text className={'text-h2 text-gray01 dark:text-dark_gray01'}>
                    {t('copyfolio.empty.hasCopyHistory.title')}
                </Text>
                <Text className="text-gray01 dark:text-dark_gray01">{t('copyfolio.empty.hasCopyHistory.desc')}</Text>
            </div>
            <MoreButton
                onClick={onClickSendToLeaderboard}
                text={t('copyfolio.recommend.copyleader.hisytory.button.more')}
                className={'min-w-[250px] px-[30px] mx-auto mt-[20px]'}
                renderLeft={<IconPlus width={16} className={'fill-gray01 dark:fill-dark_gray01'} />}
            />
            <div className={'mt-[30px]'}>
                <Title
                    text={t('copyfolio.recommend.copyleader.hisytory.title')}
                    showArrow={true}
                    onClick={onClickCopyHistory}
                />
                <UserCopyHistoryTable copyHistory={copyHistory?.slice(0, 3)} isFetching={isFetching} />
            </div>
        </div>
    )
}

export default RecommendCopyTradingContainer
