import React from 'react'
import WizButton from '@component/buttons/WizButton'
import {useTranslation} from 'next-i18next'
import WizCheckBox from '@component/inputs/WizCheckBox'
import {useRouter} from 'next/router'
import usePreference from '@hook/usePreference'
import CheckUseProComponent from '@feature/portfolio/components/CheckUseProComponent/CheckUseProComponent'
import {createLog} from '@util/logs'
import Link from 'next/link'
import {getLinkUrl, URL_BOT_HISTORY} from '@constant/url'

interface IProps {
    className?: string
    userId: string
    isPro?: boolean
}

const CopyOptionHeaderContainer: React.FC<IProps> = ({className, userId, isPro}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {isEnableHideSmallBalance, setHideSmallBalance} = usePreference()

    return (
        <div className={`${className} flex ${isPro ? 'justify-end gap-x-[15px]' : 'justify-between'} items-center`}>
            {isPro && <CheckUseProComponent className={'ml-[5px] flex-1'} />}
            <WizCheckBox
                text={t('copyfolio.hideSmallBalance.title')}
                onChanged={checked => {
                    createLog('event', 'copyfolio_hide_small_balance', {checked: checked})
                    setHideSmallBalance(checked)
                }}
                checked={isEnableHideSmallBalance}
                textClassName={'text-gray01 dark:text-dark_gray01'}
                containerClassName={`${isPro && 'flex sm:hidden'}`}
            />
            <Link href={getLinkUrl(URL_BOT_HISTORY(userId))}>
                <WizButton
                    text={t('copyfolio.copyHistory.title')}
                    buttonType={'border'}
                    showArrow={true}
                    isLink={true}
                    size={'small'}
                    onClick={() => {
                        createLog('event', 'copyfolio_click_copy_history')
                    }}
                />
            </Link>
        </div>
    )
}

export default CopyOptionHeaderContainer
