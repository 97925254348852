import React, {useRef} from 'react'
import {IKeyPortfolio} from '@api/key/portfolio/getKeyPortfolio'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'
import useFormatDate from '@hook/useFormatDate'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import CreateLeaderModal from '@feature/copy/components/CreateLeaderModal/CreateLeaderModal'
import useLogin from '@hook/useLogin'
import WizIconButton from '@component/buttons/WizIconButton'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import {useRouter} from 'next/router'
import useNavigation from '@hook/useNavigation'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import {cutMidString} from '@util/strings'
import IconLink from '@svg/common/ic_copy_link.svg'
import WizIcon from '@component/images/WizIcon'
import {createLog} from '@util/logs'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import CreateCopyModal from '@feature/copy/components/CreateCopyModal/CreateCopyModal'
import useQueryGetKeySidebar from '@hook/query/portfolio/useQueryGetKeySidebar'
import {useLoginModalStore} from '@store/LoginModalStore'

interface IProps extends IKeyPortfolio {
    className?: string
    userId?: string
    block_until?: string
    isFetching?: boolean
}

const KeyPortfolioHeader: React.FC<IProps> = ({
    className,
    exchange,
    key_name,
    created_at,
    id,
    userId,
    is_copier,
    is_leader,
    block_until,
    isFetching,
    ...props
}) => {
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {data: sidebar} = useQueryGetKeySidebar(id, false)
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {isLogin} = useLogin(sidebar?.user_id)

    const {displayDateFromText, displayDate} = useFormatDate()
    const createLeaderModalRef = useRef<any>()
    const createCopyModalRef = useRef<any>()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const router = useRouter()
    const {goLeaderboard} = useNavigation()
    const {isMe} = useLogin(userId)

    return (
        <>
            <div
                data-cy={'keyPortfolio_header'}
                className={`grid grid-cols-2 sm:grid-cols-1 gap-y-[10px] w-full ${className}`}>
                <div className={'flex items-center'}>
                    {isFetching ? (
                        <SkeletonBlock className={'w-[50px] h-[50px]'} sizeClassName={'h-full'} />
                    ) : (
                        <div
                            className={
                                'w-[50px] h-[50px] bg-white dark:bg-dark_gray09 rounded-full flex items-center flex-none justify-center border-[2px] border-gray08 dark:border-dark_gray08'
                            }>
                            <WizImage
                                src={getExchange(exchange)?.icon}
                                width={30}
                                height={30}
                                containerClassName={'flex-none'}
                            />
                        </div>
                    )}
                    <div className={'flex-none ml-[10px] space-y-[3px]'}>
                        <div className={'flex space-x-[5px] items-center'}>
                            {isFetching ? (
                                <SkeletonBlock className={'w-[150px] h-[30px]'} sizeClassName={'h-[21px]'} />
                            ) : (
                                <Text className="text-gray01 dark:text-dark_gray01">
                                    <Span className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                                        {getExchange(exchange)?.display_name}
                                    </Span>
                                    <Span
                                        className={`text-h3 text-gray02 dark:text-dark_gray02 ml-[5px] hidden sm:inline-block`}>
                                        {key_name?.length >= 15 ? cutMidString(key_name) : key_name}
                                    </Span>
                                    <Span className={`text-h3 text-gray02 dark:text-dark_gray02 ml-[5px] sm:hidden`}>
                                        {key_name}
                                    </Span>
                                </Text>
                            )}
                            <WizIconButton
                                className={`${router?.asPath?.includes('portfolio') ? 'block' : 'hidden'}`}
                                onClick={() => {
                                    createLog('event', 'key_portfolio_header_copy_key_portfolio_link')
                                    copyToClipboard(`${window.location.origin}/${router.locale}/key/${id}/portfolio`)
                                    showSnackbar(t('common.copied'))
                                }}>
                                <WizIcon
                                    backgroundColorClassName={
                                        'hover:bg-transparent01 dark:hover:bg-dark_transparent01 p-[5px]'
                                    }>
                                    <IconLink
                                        className={
                                            'fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 w-[14px]'
                                        }
                                    />
                                </WizIcon>
                            </WizIconButton>
                        </div>
                        {isFetching ? (
                            <SkeletonBlock className={'w-[100px] h-[18px]'} sizeClassName={'h-[12px]'} />
                        ) : (
                            <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                {t('keyPortfolio.keyHeader.start', {
                                    date: displayDateFromText(created_at, t('dateFormat.date')),
                                })}
                            </Text>
                        )}
                    </div>
                </div>

                <div className={'flex justify-end space-x-[5px] items-center'}>
                    {!isFetching &&
                        is_copier === false &&
                        is_leader === false &&
                        getExchange(exchange)?.is_copybot_allowed === true &&
                        isMe && (
                            <WizButton
                                className={'sm:grow max-h-[35px]'}
                                text={t('keyPortfolio.keyHeader.registerLeader')}
                                buttonType={'golden'}
                                size={'small'}
                                disabled={block_until ? true : false}
                                onClick={() => {
                                    createLog('event', 'key_portfolio_header_open_enroll_leader_modal')
                                    createLeaderModalRef.current?.show()
                                }}
                            />
                        )}
                    {!isFetching &&
                        is_leader === false &&
                        isMe === true &&
                        is_copier === false &&
                        getExchange(exchange)?.is_copybot_allowed === true && (
                            <WizButton
                                className={'sm:grow max-h-[35px]'}
                                text={t('keyPortfolio.keyHeader.startCopy')}
                                buttonType={'secondary'}
                                size={'small'}
                                onClick={() => {
                                    createLog('event', 'key_portfolio_header_click_start_copy')
                                    goLeaderboard('copyleader')
                                }}
                            />
                        )}
                    {!isFetching && is_leader === true && isMe === false && is_copier === false && (
                        <div
                            className={'hidden sm:block mt-[10px] w-full'}
                            onClick={e => {
                                e.stopPropagation()
                                if (sidebar?.can_copy === false) {
                                    showSnackbar(t('portfolio.sidebar.copy.unavailable'))
                                    return
                                }

                                if (!isLogin()) {
                                    showLoginModal()
                                    return
                                }
                                createLog('event', 'key_portfolio_sidebar_open_copy_modal')
                                createCopyModalRef?.current?.show()
                            }}>
                            <WizButton
                                textClassName={''}
                                text={t('portfolio.sidebar.mobile.copy.title')}
                                className={'w-full'}
                                size={'small'}
                            />
                        </div>
                    )}
                </div>
            </div>
            <CreateCopyModal ref={createCopyModalRef} leaderUserId={userId} />
            <CreateLeaderModal ref={createLeaderModalRef} />
        </>
    )
}

export default KeyPortfolioHeader
